import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../components/floating-button/floating-button';
import Layout from '../../../components/layout';
import React from 'react';

const WorkWithUsPage = (props) => {
    const components = props.data.cms.workWithUsPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            void navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default WorkWithUsPage;

export const query = graphql`
query ($locale: String!){
  cms {
    workWithUsPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          backgroundImageHeight
          backgroundVideo {
            url
          }
          backgroundImage {
              url
          }
          contentOffset
          mobileContentOffset
          headerSubtitle: subtitle
          title
          textBoxCustomClasses
        }
        ... on STRAPI_ComponentVerticalTabsVerticalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          workOffer
          sectionTitle {
            description
            style
            tailwindClasses
            title
            titleIcon {
              url
            }
            ytLink
          }
          VerticalTabTitle {
            title
            image {
              url
            }
          }
          VerticalTabContent {
            contentRichText
            title
            titleImage {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsWorkWithUsContactForm {
          __typename
          emailAddressLabel
          messageLabel
          nameAndSurnameLabel
          personalDataText
          phoneNumberLabel
          positionsToApply
          positionsToApplyLabel
          sectionTitle {
            description
            style
            tailwindClasses
            title
            titleIcon {
              url
            }
            ytLink
          }
          sendButtonLabel
          uploadCVLabel
          upperText
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
            __typename
            topPusherHeightMobile
            topPusherHeight
            tabs {
              type
              title
              subtitle
              scrollColor
              lottieData
              link
              labelImage {
                url
              }
              labelColour
              label
              isLabelImageVisibleOnDesktop
              imagesTitles
              imagesMetadata
              images {
                url
              }
              fullWidthImageItemsDirection
              sendButtonLabel
              formFieldsData
            }
            tabsContentColor
            tabHeight
            sectionTitle {
              tailwindClasses
              ytLink
              title
              style
              description
            }
          }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
      }
    }
  }
}
`;
